import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase_services";
import UserDataModel, { ClientModel } from "../../models/UserDataModel";
import { DriverModel } from "../../models/DriverModel";
import { StateType } from "../../models/BookingModels";

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getClientList = createAsyncThunk(
  "GetClientList",
  async (): Promise<ClientModel[] | undefined> => {
    try {
      let clients = db.ref("users/");
      let clientList: ClientModel[] = [];

      let clientsFound = await clients.once("value");

      if (
        clientsFound !== null &&
        clientsFound.val() &&
        clientsFound.val() !== null
      ) {
        let filterList: UserDataModel[] = Object.values(clientsFound.val());

        let list = filterList.filter((val: any) => {
          if (!val.driver) {
            return val;
          }
        });

        if (list) {
          for (let i = 0; i < list.length; i++) {
            let listItem = list[i];
            let client: ClientModel = {
              id: listItem.id,
              user: {
                id: listItem.id,
                firstName: listItem.firstName,
                lastName: listItem.lastName,
                emailAddress: listItem.emailAddress,
                phoneNumber: listItem.phoneNumber,
                profilePictureUrl: listItem.profilePictureUrl,
                creationDateTime: listItem.creationDateTime,
                customerId: listItem.customerId,
                rating: listItem.rating,
                lastActiveDate: listItem.lastActiveDate,
              },
              totalRides: 0,
              totalRidesFinished: 0,
              joinedAt: listItem.creationDateTime,
            };

            let user_rides = db.ref(
              "/booking_data/user_bookings/" + listItem.id
            );

            let user__rides_data = await user_rides.once("value");

            if (user__rides_data !== null && user__rides_data.val() !== null) {
              client = {
                ...client,
                totalRides: Object.values(user__rides_data.val()).length,
                totalRidesFinished: Object.values(
                  user__rides_data.val()
                ).filter(
                  (val: any) =>
                    val.state === StateType.GENERAL_CUSTOMER_ENDED_EARLY ||
                    val.state === StateType.GENERAL_TRIP_FINISHED ||
                    val.state === StateType.GENERAL_DRIVER_ENDED_EARLY
                ).length,
              };
            }
            clientList = [...clientList, client];
          }
        }

        return clientList;
      }

      return clientList;
    } catch (err: any) {
      console.log(err);
    }
  }
);

export const getClientById = createAsyncThunk(
  "GetClient",
  async ({ id }: { id: string }): Promise<ClientModel | undefined> => {
    try {
      let clientDb = db.ref("users/" + id);

      let clientFound = await clientDb.once("value");

      if (clientFound !== null && clientFound.val() !== null) {
        let listItem = clientFound.val();
        let client: ClientModel = {
          id: listItem.id,
          user: {
            id: listItem.id,
            firstName: listItem.firstName,
            lastName: listItem.lastName,
            emailAddress: listItem.emailAddress,
            phoneNumber: listItem.phoneNumber,
            profilePictureUrl: listItem.profilePictureUrl,
            creationDateTime: listItem.creationDateTime,
            customerId: listItem.customerId,
            lastActiveDate: listItem.lastActiveDate,
            rating: listItem.rating,
          },
          totalRides: 0,
          totalRidesFinished: 0,
          joinedAt: listItem.creationDateTime,
        };

        let user_rides = db.ref("/booking_data/user_bookings/" + listItem.id);

        let user__rides_data = await user_rides.once("value");

        if (user__rides_data !== null && user__rides_data.val() !== null) {
          client = {
            ...client,
            totalRides: Object.values(user__rides_data.val()).length,
            totalRidesFinished: Object.values(user__rides_data.val()).filter(
              (val: any) =>
                val.state === StateType.GENERAL_CUSTOMER_ENDED_EARLY ||
                val.state === StateType.GENERAL_TRIP_FINISHED ||
                val.state === StateType.GENERAL_DRIVER_ENDED_EARLY
            ).length,
          };
        }
        return client;
      }
    } catch (err: any) {
      console.log(err);
    }
  }
);
