import * as React from "react";
import { Grid, PaletteMode, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "../components/home/AppAppBar";
import getLPTheme from "./getLPTheme";
import { useEffect } from "react";

const defaultTheme = createTheme({});

export default function PrivacyEulaScreen() {
  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  useEffect(() => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  }, []);

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />

      <Grid
        item
        container
        xs={12}
        justifyContent={"center"}
        sx={{ paddingTop: 5 }}
      >
        <Grid
          item
          xs={10}
          container
          flexDirection={"column"}
          className="policies"
        >
          <Typography variant="h4">End User Licence Agreement</Typography>

          <Typography variant="h5">
            PLEASE READ THESE LICENCE TERMS CAREFULLY
          </Typography>
          <Typography variant="body1">
            This licence agreement (Licence) is a legal agreement between you
            (Licensee or you) and GEO-GO LIMITED of 1 Capital Quarter, Tyndall
            Street, Cardiff, Wales, CF10 4BZ (Licensor, us or we) for:
            <br />
            Geo Go mobile application software, the data supplied with the
            software, and the associated media (Software); and We license use of
            the Software to you on the basis of this Licence. We do not sell the
            Software to you. We remain the owners of the Software at all times.
          </Typography>
          <Typography variant="h5">TECHNICAL SPECIFICATIONS</Typography>
          <Typography variant="body1">
            For Android Devices:
            <br />
            Operating System: Android with a minimum version of Android 8.0 (API
            level 24) or later.
            <br />
            Memory: It is recommended to have at least 2 GB of RAM (Random
            Access Memory) or higher for optimal performance.
            <br />
            For iOS Devices:
            <br />
            Operating System: iOS with a minimum version of iOS 12 or later.
            <br />
            Memory: It is recommended to have at least 2 GB of RAM or higher for
            optimal performance.
            <br />
            Please ensure that your device meets these specifications to ensure
            optimal performance and compatibility with the app.
          </Typography>
          <Typography variant="h5">AGREED TERMS</Typography>
          <Typography variant="body1">
            <strong>
              GRANT AND SCOPE OF LICENCE, MINOR CHANGES, UPDATES AND UPGRADES
            </strong>
            <br />
            1.1 In consideration of you agreeing to abide by the terms of this
            Licence, the Licensor hereby grants to you a non-exclusive,
            non-transferable licence to use the Software in the UK on the terms
            of this Licence.
            <br />
            1.2 You may:
            <br />
            (a) install and use the Software for your personal purposes only, on
            one device per account;
            <br />
            (b) provided it is used at any one time on only one device owned or
            leased by you, access and use the Software;
            <br />
            (c) receive and use any free supplementary software code or update
            of the Software incorporating "patches" and corrections of errors as
            may be provided by the Licensor from time to time.
            <br />
            1.3 We may update or require you to update the Software, provided
            that the Software shall always match the description of it that we
            provided to you before you installed it.
            <br />
            1.4 The Software may be upgraded to reflect changes in the Operating
            System. The Software will work with the current or previous version
            of that Operating System (as it may be updated from time to time).
            <br />
            <strong>RESTRICTIONS</strong>
            <br />
            2.1 Except as expressly set out in this Licence or as permitted by
            any local law, you undertake:
            <br />
            (a) not to copy the Software, except where such copying is
            incidental to normal use of the Software or where it is necessary
            for the purpose of back-up or operational security;
            <br />
            (b) not to rent, lease, sub-license, loan, translate, merge, adapt,
            vary, alter or modify, the whole or any part of the Software nor
            permit the Software or any part of it to be combined with, or become
            incorporated in, any other programs;
            <br />
            (c) not to disassemble, de-compile, reverse engineer or create
            derivative works based on the whole or any part of the Software nor
            attempt to do any such things, except to the extent that (by virtue
            of sections 50B and 296A of the Copyright, Designs and Patents Act
            1988) such actions cannot be prohibited because they are necessary
            to decompile the Software to obtain the information necessary to
            create an independent program that can be operated with the Software
            or with another program (Permitted Objective), and provided that the
            information obtained by you during such activities:
            <br />
            (i) is used only for the Permitted Objective;
            <br />
            (ii) is not disclosed or communicated without the Licensor's prior
            written consent to any third party to whom it is not necessary to
            disclose or communicate it in order to achieve the Permitted
            Objective; and
            <br />
            (iii) is not used to create any software that is substantially
            similar in its expression to the Software;
            <br />
            (d) to keep all copies of the Software secure and to maintain
            accurate and up-to-date records of the number and locations of all
            copies of the Software;
            <br />
            (e) to include our copyright notice on all entire and partial copies
            of the Software in any form;
            <br />
            (f) not to provide, or otherwise make available, the Software in any
            form, in whole or in part (including, but not limited to, program
            listings, object and source program listings, object code and source
            code) to any person without prior written consent from us;
            <br />
            (g) to comply with all applicable technology control or export laws
            and regulations.
            <br />
            <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
            <br />
            3.1 You acknowledge that all intellectual property rights in the
            Software throughout the world belong to us, that rights in the
            Software are licensed (not sold) to you, and that you have no
            intellectual property rights in, or to, the Software other than the
            right to use the Software and in accordance with the terms of this
            Licence.
            <br />
            3.2 You acknowledge that you have no right to have access to the
            Software in source code form other than as expressly provided in
            this Licence.
            <br />
            <strong>
              OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
            </strong>
            <br />
            4.1 When we are liable for damage to your property. If defective
            digital content that we have supplied damages a device or digital
            content belonging to you, we will either repair the damage or pay
            you compensation. However, we will not be liable for damage that you
            could have avoided by following our advice to apply an update
            offered to you free of charge or for damage that was caused by you
            failing to correctly follow installation instructions or to have in
            place the minimum system requirements advised by us.
            <br />
            We are not liable for business losses. The Software is for domestic
            and private use. If you use the Software for any commercial,
            business or resale purpose we will have no liability to you for any
            loss of profit, loss of business, business interruption, or loss of
            business opportunity.
            <br />
            <strong>TERMINATION</strong>
            <br />
            5.1 We may terminate this Licence immediately by written notice to
            you if you commit a material or persistent breach of this Licence
            which you fail to remedy (if remediable) within 14 days after the
            service of written notice requiring you to do so.
            <br />
            5.2 Upon termination for any reason:
            <br />
            (a) all rights granted to you under this Licence shall cease;
            <br />
            (b) you must cease all activities authorised by this Licence; and
            <br />
            (c) you must immediately delete or remove the Software from all
            computer equipment and electronic devices in your possession.
            <br />
            <strong>COMMUNICATIONS BETWEEN US</strong>
            <br />
            6.1 If you are a consumer, if you wish to contact us in writing, or
            if any condition in this Licence requires you to give us notice in
            writing, you can send this to us by email or by pre-paid post to
            GEO-GO LIMITED at 1 Capital Quarter, Tyndall Street, Cardiff, Wales,
            CF10 4BZ / EMAIL ADDRESS. We will confirm receipt of this by
            contacting you in writing, normally by email.
            <br />
            6.2 If we have to contact you or give you notice in writing, we will
            do so by email or by pre-paid post to the address you provide or
            confirm to us.
            <br />
            <strong>HOW WE MAY USE YOUR PERSONAL INFORMATION</strong>
            <br />
            7.1 Under data protection legislation, we are required to provide
            you with certain information about who we are, how we process your
            personal data and for what purposes and your rights in relation to
            your personal data and how to exercise them. This information is
            provided in the privacy policy within this webpage and it is
            important that you read that information.
            <br />
            <strong>OTHER IMPORTANT TERMS</strong>
            <br />
            8.1 We may transfer our rights and obligations under these terms to
            another organisation.
            <br />
            8.2 You may not transfer your rights or your obligations under this
            Licence to another person.
            <br />
            8.3 This agreement does not give rise to any rights under the
            Contracts (Rights of Third Parties) Act 1999 to enforce any term of
            this agreement.
            <br />
            8.4 Each of the conditions of these terms operates separately. If
            any court or relevant authority decides that any of them are
            unlawful, the remaining conditions will remain in full force and
            effect.
            <br />
            8.5 If we do not insist immediately that you do anything you are
            required to do under these terms, or if we delay in taking steps
            against you in respect of your breaking this contract, that will not
            mean that you do not have to do those things and it will not prevent
            us taking steps against you at a later date.
            <br />
            <strong>APPLICABLE LAW AND JURISDICTION</strong>
            <br />
            These terms are governed by English law and you can bring legal
            proceedings in respect of the products in the English courts. If you
            live in Scotland you can bring legal proceedings in respect of the
            products in either the Scottish or the English courts. If you live
            in Northern Ireland you can bring legal proceedings in respect of
            the products in either the Northern Irish or the English courts.
            <br />
            <strong>ACCEPTANCE</strong>
            <br />
            BY CLICKING ON THE "ACCEPT" BUTTON BELOW, YOU ACKNOWLEDGE THAT YOU
            HAVE READ AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
            LICENCE.
          </Typography>
          <Typography variant="h5">MISUSE AND ABUSE</Typography>
          <Typography variant="body1">
            9.1 Prohibited activities include but are not limited to: abusing
            drivers, damaging driver property, misusing the Geo Go app, abusing
            any exploits on the software, distributing malware, and unauthorized
            attempts to disrupt the app's functionality.
            <br />
            9.2 Consequences of misuse may result in immediate corrective action
            by Geo Go, including the removal of accounts and illicit content.
          </Typography>
          <Typography variant="h5">LIMITATION OF LIABILITY</Typography>
          <Typography variant="body1">
            10.1 Geo Go encourages users to utilize the full range of the
            application to ensure their safety. This includes utilizing features
            such as the passcode system to verify drivers/customers, the
            messaging system, and driver images.
          </Typography>
          <Typography variant="h5">THIRD-PARTY SERVICES</Typography>
          <Typography variant="body1">
            11.1 By using the Geo Go app, you automatically agree to the terms
            and conditions of third-party services such as Google Places,
            Mapbox, and Firebase where needed.
          </Typography>
          <Typography variant="h5">TERMINATION</Typography>
          <Typography variant="body1">
            12.1 Users may delete their account at any time, but Geo Go reserves
            the right to retain data relating to trips.
          </Typography>

          {/* Privacy */}
          <Typography variant="h5">Privacy Policy</Typography>
          <Typography variant="subtitle1">
            Last Updated: 8th April 2024
          </Typography>
          <Typography variant="body1">
            Welcome to Geo Go! This Privacy Policy explains how Geo Go Limited
            ("Geo Go," "we," "us," or "our") collects, uses, shares, and
            protects information about you when you use our mobile application,
            website, and related services (collectively, the "Services"). By
            accessing or using our Services, you agree to this Privacy Policy.
          </Typography>

          <Typography variant="h5">1. Information We Collect</Typography>
          <Typography variant="body1">
            Geo Go collects and processes information about you to provide and
            improve our Services. The types of information we collect depend on
            your interactions with our platform and may include:
          </Typography>
          <Typography variant="body1">
            <strong>Personal Information:</strong> When you sign up for an
            account, we collect information such as your name, email address,
            phone number, and university (if applicable).
          </Typography>
          <Typography variant="body1">
            <strong>Trip Information:</strong> We gather data related to your
            trips, including pickup and drop-off locations, trip duration, and
            distance traveled.
          </Typography>
          <Typography variant="body1">
            <strong>Location Data:</strong> During a trip, we track your
            location to facilitate communication between drivers and customers
            and to provide accurate trip information.
          </Typography>

          <Typography variant="h5">2. How We Use Your Information</Typography>
          <Typography variant="body1">
            We use the information we collect for various purposes, including:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                Providing and maintaining our Services;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Communicating with you about your account, bookings, and updates
                to our Services;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Personalizing your experience and improving our Services;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Analyzing trends and user behavior to enhance our offerings;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Complying with legal obligations and enforcing our Terms of
                Service.
              </Typography>
            </li>
          </ul>

          <Typography variant="h5">3. Data Security</Typography>
          <Typography variant="body1">
            Geo Go takes the security of your information seriously and employs
            robust measures to protect it. We use industry-standard encryption
            protocols, secure server hosting, and access controls to safeguard
            your data against unauthorized access, disclosure, alteration, or
            destruction.
          </Typography>

          <Typography variant="h5">4. Data Sharing Practices</Typography>
          <Typography variant="body1">
            We do not share your personal information with third parties except
            as necessary to provide our Services or as required by law. Your
            location data is shared with drivers during a trip to facilitate
            pickup and drop-off.
          </Typography>

          <Typography variant="h5">5. Data Access and Correction</Typography>
          <Typography variant="body1">
            You can access and update your personal information through your Geo
            Go account settings. If you need assistance or wish to delete your
            account and associated data, please contact our administrative team
            at <a href="mailto:admin@geo-go-exec.com">admin@geo-go-exec.com</a>.
          </Typography>

          <Typography variant="h5">
            6. Compliance with GDPR and UK Laws
          </Typography>
          <Typography variant="body1">
            Geo Go complies with the General Data Protection Regulation (GDPR)
            and UK data protection laws. We process your personal data lawfully
            and transparently and respect your rights under these regulations.
          </Typography>

          <Typography variant="h5">7. Data Retention Policy</Typography>
          <Typography variant="body1">
            We retain your personal information indefinitely to identify trends
            and make strategic business decisions. Personal data is deleted upon
            account deletion, and you may request access to or deletion of your
            data by contacting us.
          </Typography>

          <Typography variant="h5">8. Changes to the Privacy Policy</Typography>
          <Typography variant="body1">
            We reserve the right to update or modify this Privacy Policy at any
            time. We will notify you of any material changes by posting the
            updated policy on our website or through other appropriate means.
          </Typography>

          <Typography variant="h5">
            9. Jurisdiction and Applicable Law
          </Typography>
          <Typography variant="body1">
            This Privacy Policy is governed by English law, and any disputes
            arising from it are subject to the exclusive jurisdiction of the
            English courts.
          </Typography>

          <Typography variant="h5">10. Effective Date</Typography>
          <Typography variant="body1">
            This Privacy Policy is effective as of the date indicated at the top
            of the document and supersedes any prior versions.
          </Typography>

          <Typography variant="h5">11. Contact Us</Typography>
          <Typography variant="body1">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our data practices, please contact us at:
          </Typography>
          <Typography variant="body1">Geo Go Limited</Typography>
          <Typography variant="body1">
            1 Capital Quarter, Tyndall Street
          </Typography>
          <Typography variant="body1">Cardiff, Wales, CF10 4BZ</Typography>
          <Typography variant="body1">
            Email:{" "}
            <a href="mailto:admin@geo-go-exec.com">admin@geo-go-exec.com</a>
          </Typography>

          <Typography variant="body1">
            Legal Disclaimer: This Privacy Policy is provided for informational
            purposes only and does not constitute legal advice. While we strive
            to ensure the accuracy and completeness of the information herein,
            we make no representations or warranties of any kind, express or
            implied, about the accuracy, reliability, suitability, or
            availability of this information.
          </Typography>

          <Typography variant="body1">
            Acceptance of Policy: By using our Services, you acknowledge that
            you have read, understood, and agree to be bound by this Privacy
            Policy. If you do not agree to this policy, please do not use our
            Services.
          </Typography>

          <Typography variant="body1">
            This Privacy Policy aims to provide you with a clear and transparent
            understanding of how Geo Go collects, uses, and protects your
            information. We are committed to maintaining your privacy and
            ensuring the security of your data. Thank you for choosing Geo Go!
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
