import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { DriverModel } from "../../../models/DriverModel";
import { Launch } from "@mui/icons-material";
import {
  getCurrentDateTime,
  useTools,
} from "../../../services/general_services";
import { setSelectedViewClient } from "../../../store/slices/client.slice";

export function ClientGrid({ rows }: { rows: DriverModel[] }) {
  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "User",
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid
            item
            container
            xs={12}
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
            style={{
              height: "52px",
            }}
            flexDirection={"row"}
          >
            {/* Circle Profile Pic */}
            <Grid
              item
              container
              xs={4}
              sx={{ height: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Avatar
                style={{
                  width: "28px",
                  height: "28px",
                  marginRight: 10,
                }}
                alt={params.row.user.firstName}
                src={params.row.user.profilePictureUrl}
              />
            </Grid>

            {/* Profile Information */}
            <Grid item xs={8} flexDirection={"column"}>
              <Typography
                variant={"h6"}
                style={{ lineHeight: 1.2, fontSize: 15 }}
              >
                {params.row.user.firstName}
              </Typography>
              <Typography
                variant={"body1"}
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontFamily: "Roboto",
                  fontWeight: 200,
                  fontSize: 12,
                }}
              >
                +44 {params.row.user.phoneNumber}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },

    {
      field: "totalRides",
      headerName: "Total Rides",
      flex: 0.5,
    },
    {
      field: "totalRidesFinished",
      headerName: "Total Finished",
      flex: 0.5,
    },
    {
      field: "joinedAt",
      headerName: "Joined Since",
      flex: 1,
      renderCell: (params) => {
        const datePast = moment(params.value);

        return (
          <div
            style={{
              backgroundColor: "rgba(90,96,255,0.2)",
              color: "#5A60FF",
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 25,
            }}
          >
            {datePast.from(getCurrentDateTime())}
          </div>
        );
      },
    },
    {
      field: "lastActiveDate",
      headerName: "Last Active",
      flex: 1,
      valueGetter: (params) => {
        return params.row.user.lastActiveDate
          ? params.row.user.lastActiveDate
          : 909999999;
      },
      renderCell: (params) => {
        const datePast = moment(params.row.user.lastActiveDate);

        return (
          <div
            style={{
              backgroundColor: "rgba(90,96,255,0.2)",
              color: "#5A60FF",
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 25,
            }}
          >
            {params.row.user.lastActiveDate
              ? datePast.from(getCurrentDateTime())
              : "Never"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton
            key={"id2"}
            onClick={() => {
              dispatch(setSelectedViewClient(params.row));
              navigate("/admin/clients/" + params.row.id);
            }}
          >
            <Launch />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: "none",
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ minHeight: 250 }}
      onRowClick={(e) => {
        navigate("/admin/clients/" + e.row.user.id);
      }}
    />
  );
}
