import { useEffect, useState } from "react";
import { useTools } from "../../services/general_services";
import { setPage } from "../../store/slices/general.slice";
import { PageType } from "../../models/PageType";
import { Add } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { setCreateNewDriver } from "../../store/slices/driver.slice";
import { DriverGrid } from "../components/drivers/DriverGrid";
import DarkTextField from "../components/general/TextFieldDarker";
import { ClientModel } from "../../models/UserDataModel";
import { ClientGrid } from "../components/clients/ClientGrid";
import { useSelector } from "react-redux";
import { setCreateNewClient } from "../../store/slices/client.slice";
import { getCarClassList } from "../../store/thunks/carClass.thunks";
import { getClientList } from "../../store/thunks/client.thunks";

export function ClientsScreen() {
  const { dispatch, navigate } = useTools();
  const { clientList, clientListLoading } = useSelector(
    (state: any) => state.ClientSlice
  );

  const [searchClientValue, setSearchClientValue] = useState("");

  const [clientListRetrieved, setClientListRetrieved] = useState(false);

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    dispatch(setPage(PageType.CLIENTS));
    if (!clientListRetrieved) {
      dispatch(getClientList());
      setClientListRetrieved(true);
    }
  }, []);

  // Update local list with data from client list
  useEffect(() => {
    setFilteredList(clientList);
  }, [clientList]);

  // Search through items
  useEffect(() => {
    if (searchClientValue === "") {
      setFilteredList(clientList);
    } else {
      let searchValue = searchClientValue;
      if (searchClientValue[0] === "0") {
        searchValue = searchClientValue.slice(1);
      }
      let list = clientList.filter(
        (val: ClientModel) =>
          val.user.firstName
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          val.user.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
          val.user.emailAddress
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          val.totalRides.toString().includes(searchValue.toLowerCase()) ||
          val.totalRidesFinished.toString().includes(searchValue.toLowerCase())
      );
      setFilteredList(list);
    }
  }, [searchClientValue]);

  useEffect(() => {
    dispatch(setPage(PageType.CLIENTS));
  }, []);
  return (
    <Grid
      container
      item
      xs={12}
      sx={{ padding: 2 }}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      {/* Top section - Search & Filter */}
      <Grid item container xs={12} alignItems={"center"}>
        <Grid item container xs={9} alignItems={"center"}>
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            style={{ marginRight: 30 }}
          >
            Clients
          </Typography>

          <DarkTextField
            placeholder="Search Clients..."
            value={searchClientValue}
            onChange={(e) => {
              setSearchClientValue(e.target.value);
            }}
            style={{
              width: "200px",
              borderRadius: 30,
              marginRight: 30,
            }}
            sx={{ marginBottom: { xs: 1, sm: 0 } }}
          />
        </Grid>
      </Grid>
      {/* Bottom section - Client Grid */}
      <Grid
        item
        container
        xs={12}
        sx={{ marginTop: 5 }}
        justifyContent={"center"}
      >
        {clientListLoading ? (
          <CircularProgress />
        ) : (
          <ClientGrid rows={filteredList} />
        )}
      </Grid>
    </Grid>
  );
}
